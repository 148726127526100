<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <AppLoader v-if="generalSettings === null || (authToken !== null && authUser.user === null)" key="loading" />
      <div 
        v-else-if="generalSettings.general.maintenance.enabled === false" 
        class="app-page" 
        key="page"
      >
        <Chat />
        <Navbar />
        <!-- Admin rotasına göre Navbar ve Sidebar'ı gizle -->
        <template v-if="!isAdminRoute">
          
          <Sidebar @open-jackpot-modal="openJackpotModal" />
        </template>

        <ModalJackpot v-if="isJackpotModalOpen" @close="closeJackpotModal" />
        
        <main 
          v-bind:class="{
            'main-background': appHasBackground === true, 
            'main-shifted-sidebar': isSidebarClosed,  // Sidebar kapalıyken içerik sola kayar
            'main-expanded-sidebar': !isSidebarClosed,  // Sidebar açıkken içerik genişler
            'main-shifted': generalSidebarMobile === 'Chat',
            'main-expanded': generalSidebarMobile !== 'Chat',
          }" 
          ref="mainContainer"
        >
          <transition name="slide-fade" mode="out-in">
            <router-view />
          </transition>
          <template v-if="!isAdminRoute">
          
            <Footer />
        </template>
          
        </main>
        
        <BottomNavigation /> <!-- Yeni Navigasyon Eklendi -->
        <Modals />
        <Notifications />
      </div>
      <AppMaintenance v-else key="maintenance" />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppLoader from '@/components/AppLoader';
import AppMaintenance from '@/components/AppMaintenance';
import Navbar from '@/components/navbar/Navbar';
import Chat from '@/components/chat/Chat';
import Footer from '@/components/footer/Footer';
import Modals from '@/components/modals/Modals';
import Notifications from '@/components/notifications/Notifications';
import BottomNavigation from '@/components/BottomNavigation'; // Yeni bileşen import edildi
import Sidebar from '@/components/sidebar'; // Yeni bileşen import edildi
import ModalJackpot from '@/components/modals/ModalJackpot.vue';

export default {
  components: {
    AppLoader,
    AppMaintenance,
    Navbar,
    Sidebar,
    ModalJackpot,
    Chat,
    Footer,
    Modals,
    Notifications,
    BottomNavigation, // Yeni bileşen eklendi
  },
  methods: {
    ...mapActions(['socketConnectGeneral', 'toggleSidebar', 'openJackpotModal', 'closeJackpotModal']),
    toggleSidebarState() {
      this.toggleSidebar();  // Sidebar'ı açıp kapatan Vuex aksiyonunu çağırıyoruz
    }
  },
  computed: {
    ...mapGetters(['socketGeneral', 'isJackpotModalOpen', 'isSidebarClosed', 'generalSettings', 'authToken', 'authUser', 'generalSidebarMobile']),
    appGetRouteName() {
      return this.$route.name;
    },
    appHasBackground() {
      let background = true;
      if (['ProfileTransactions', 'ProfileGames', 'ProfileSettings', 'UnboxOverview', 'UnboxBox', 'BattlesOverview', 'BattlesCreate', 'BattlesGame', 'CashierLimiteds'].includes(this.appGetRouteName) === true) {
        background = false;
      }
      return background;
    },
    isAdminRoute() {
      return this.$route.path.startsWith('/admin'); // Admin rotasını kontrol et
    },
  },
  watch: {
    '$route': {
      handler(to, from) {
        if (this.$refs.mainContainer !== undefined) {
          this.$nextTick(() => {
            const mainContainer = this.$refs.mainContainer;
            mainContainer.scrollTo({ top: 0, behavior: 'smooth' });
          });
        }
      }
    }
  },
  created() {
    this.socketConnectGeneral();
  }
}
</script>

<style>
body .app-loader.fade-leave-active {
  transition: opacity 0.5s ease;
}

body .app-loader.fade-leave-active {
  opacity: 0;
}

body .app-page {
  width: 100%;
  height: 100%;
}

body .app-page.fade-enter-active,
body .app-maintenance.fade-enter-active {
  transition: opacity 0.5s ease;
}

body .app-page.fade-enter-from,
body .app-maintenance.fade-enter-from {
  opacity: 0;
}

body .app-page main {
  width: calc(100% - 475px);
  height: calc(100% - 100px);
  position: fixed;
  top: 100px;
  right: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Kaliteli kayma animasyonu */
}

/* PC arayüzü için chat açıkken içerik sola kayar */
@media (min-width: 1176px) {
 


  
  body .app-page main.main-expanded-sidebar {
    width: calc(100% - 475px); /* Sidebar açıkken içerik genişler */
  }

  /* Sidebar kapalıyken içerik biraz sola kayar */
  body .app-page main.main-shifted-sidebar {
    width: calc(100% - 275px); /* Sidebar kapalıyken içerik biraz sola kayar */
    right: 100px;
  }
}

/* Mobil arayüz (1175px ve altı) için chat ile ilgili kayma işlemi yapılmıyor */
/* Mobil arayüz için (chat veya sidebar kaydırma uygulanmaz) */
@media (max-width: 1175px) {
  body .app-page main {
    width: 100%;
    right: 0;
  }
}



body .app-page main::-webkit-scrollbar-track {
  background-color: transparent;
}

body .app-page main::-webkit-scrollbar-thumb {
  background-color: transparent;
}


body .app-page main::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body .app-page main .slide-fade-enter-active {
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Kaliteli kayma animasyonu */
}

body .app-page main .slide-fade-enter {
  opacity: 0;
}

@media only screen and (max-width: 1500px) {
  body .app-page main {
    width: 100%;
    right: 0;
  }
}

@media only screen and (max-width: 1175px) {
  body .app-page main {
    height: calc(100% - 80px);
    top: 80px;
  }
}
</style>
