<template>
    <div class="transaction-history-container">
      <h2>{{ $t('İşlem Geçmişi') }}</h2>
  
      <!-- Deposit ve Withdrawal Butonları -->
      <div class="filter-buttons">
        <button
          :class="{ active: activeFilter === 'deposit' }"
          @click="setFilter('deposit')"
        >
          {{ $t('Para Yatırma') }}
        </button>
        <button
          :class="{ active: activeFilter === 'withdrawal' }"
          @click="setFilter('withdrawal')"
        >
          {{ $t('Para Çekme') }}
        </button>
      </div>
  
      <!-- Masaüstü Tablo -->
      <table class="transaction-table desktop-table">
        <thead>
          <tr>
            <th>{{ $t('İşlem Türü') }}</th>
            <th>{{ $t('Miktar') }}</th>
            <th>{{ $t('Durum') }}</th>
            <th>{{ $t('Tarih') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(transaction, index) in paginatedTransactions" :key="index">
            <td>{{ transaction.type === 'deposit' ? 'Deposit' : 'Withdrawal' }}</td>
            <td>{{ transaction.amount }}</td>
            <td>
              <span :class="getStatusClass(transaction.status || transaction.state)">
                {{ transaction.status || transaction.state }}
              </span>
            </td>
            <td>{{ formatDate(transaction.createdAt) }}</td>
          </tr>
        </tbody>
      </table>
  
      <!-- Mobil Kart Görünümü -->
      <div class="mobile-cards">
        <div v-for="(transaction, index) in paginatedTransactions" :key="index" class="card">
          <div class="card-row">
            <span class="label">{{ $t('İşlem Türü') }}:</span>
            <span class="value">{{ transaction.type === 'deposit' ? 'Deposit' : 'Withdrawal' }}</span>
          </div>
          <div class="card-row">
            <span class="label">{{ $t('Miktar') }}:</span>
            <span class="value">{{ transaction.amount }}</span>
          </div>
          <div class="card-row">
            <span class="label">{{ $t('Durum') }}:</span>
            <span :class="getStatusClass(transaction.status || transaction.state)">
              {{ transaction.status || transaction.state }}
            </span>
          </div>
          <div class="card-row">
            <span class="label">{{ $t('Tarih') }}:</span>
            <span class="value">{{ formatDate(transaction.createdAt) }}</span>
          </div>
        </div>
      </div>
  
      <!-- Sayfalandırma -->
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">{{ $t('Önceki') }}</button>
        <span>{{ $t('Sayfa') }} {{ currentPage }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('Sonraki') }}</button>
        <span class="total-records">{{ $t('Toplam Kayıt') }}: {{ filteredTransactions.length }}</span>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import axios from 'axios';
  
  export default {
    data() {
      return {
        transactions: [], // Tüm işlemler (başlangıçta boş dizi)
        activeFilter: 'deposit', // Aktif filtre (deposit veya withdrawal)
        currentPage: 1, // Mevcut sayfa
        itemsPerPage: 15, // Sayfa başına gösterilecek veri sayısı
      };
    },
    computed: {
      ...mapGetters(['authUser']),
  
      // Filtrelenmiş işlemler (deposit veya withdrawal)
      filteredTransactions() {
        if (!this.transactions) return []; // transactions undefined ise boş dizi döndür
        return this.transactions.filter(
          (transaction) => {
            // API'den gelen "withdraw" değerini "withdrawal" olarak eşleştir
            const type = transaction.type === 'withdraw' ? 'withdrawal' : transaction.type;
            return type === this.activeFilter;
          }
        );
      },
  
      // Sayfalandırılmış işlemler
      paginatedTransactions() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.filteredTransactions.slice(start, end);
      },
  
      // Toplam sayfa sayısı
      totalPages() {
        return Math.ceil(this.filteredTransactions.length / this.itemsPerPage);
      },
    },
    mounted() {
      this.fetchTransactionHistory();
    },
    methods: {
      // API'den işlem geçmişini çek
      async fetchTransactionHistory() {
        try {
          const userIdentifier = this.authUser.user._id || this.authUser.user.phone;
          const response = await axios.get(`/transaction-history/${userIdentifier}`);
          this.transactions = response.data.transactions || []; // transactions undefined ise boş dizi ata
        } catch (error) {
          console.error('İşlem geçmişi çekilirken hata oluştu:', error);
        }
      },
  
      // Filtre değiştirme (deposit veya withdrawal)
      setFilter(filter) {
        this.activeFilter = filter;
        this.currentPage = 1; // Filtre değiştiğinde sayfayı sıfırla
      },
  
      // Duruma göre renk sınıfı döndür
      getStatusClass(status) {
        switch (status.toLowerCase()) {
          case 'pending':
            return 'status-pending';
          case 'completed':
            return 'status-completed';
          case 'rejected':
            return 'status-rejected';
          default:
            return '';
        }
      },
  
      // Tarih formatlama
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('tr-TR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });
      },
  
      // Önceki sayfa
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
  
      // Sonraki sayfa
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .transaction-history-container {
    padding: 20px;
    background: #233933;
    border-radius: 10px;
    color: #fff;
  }
  
  .filter-buttons {
    margin-bottom: 20px;
  }
  
  .filter-buttons button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .filter-buttons button.active {
    background-color: yellow;
  }
  
  .filter-buttons button:hover {
    background-color: #2c3e50;
  }
  
  /* Masaüstü Tablo Stili */
  .desktop-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    display: table;
  }
  
  .desktop-table th,
  .desktop-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #34495e;
  }
  
  .desktop-table th {
    background-color: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
    color: #FFD700;
  }
  
  .desktop-table tbody tr:hover {
    background-color: #34495e;
  }
  
  .status-pending {
    color: #ffcc00; /* Sarı */
  }
  
  .status-completed {
    color: #00cc66; /* Yeşil */
  }
  
  .status-rejected {
    color: #ff3333; /* Kırmızı */
  }
  
  /* Mobil Kart Görünümü */
  .mobile-cards {
    display: none; /* Varsayılan olarak gizli */
  }
  
  .card {
    background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .card-row .label {
    font-weight: bold;
    color: #FFD700;
  }
  
  .card-row .value {
    color: #fff;
  }
  
  /* Sayfalandırma */
  .pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .pagination button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .total-records {
    margin-left: auto;
    font-weight: bold;
  }
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .desktop-table {
      display: none; /* Mobilde tabloyu gizle */
    }
  
    .mobile-cards {
      display: block; /* Mobilde kart görünümünü göster */
    }
  
    .filter-buttons button {
      width: 100%; /* Mobilde butonlar tam genişlikte */
      margin-bottom: 10px;
    }
  }
  </style>