<template>
  <div class="home">

  <div class="banner-container">
    
   
    <!-- Home Banner Slider -->
    <div class="home-banner-slider">
      <div class="slider-container" :style="sliderStyle">
        <div
          class="banner-item"
          v-for="(banner, index) in extendedBanners"
          :key="index"
          @click="redirectToPromotion"
        >
          <img :src="banner.image" :alt="banner.alt" />
          <div class="banner-content">
            <h2 class="banner-title">{{ banner.title }}</h2>
            <p class="banner-description">{{ banner.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home-grid">
<div
  v-for="(item, index) in gridItems"
  :key="index"
  class="grid-item"
  @click="navigateTo(item.link)"
>

<img :src="item.image" :alt="item.alt" />
  
</div>
</div>

  <div class="category-buttons">
<div
  v-for="(category, index) in categoryButtons"
  :key="index"
  class="category-button"
  :class="{ active: selectedCategory === category.name }"
  @click="selectCategory(category.name)"
>
  <img :src="category.img" :alt="category.name" class="category-icon" />
  <span>{{ category.name }}</span>
</div>
</div>

<div class="game-search-container">
  <!-- Search Game Input -->
  <div class="game-search-box">
    <img src="@/assets/img/search.png" alt="Search Icon" class="game-search-icon" />
    <input
      type="text"
      class="game-search-input"
      v-model="gameSearchQuery"
      :placeholder="$t('slot.search')" 
      @focus="showSearchResult"
      @input="debouncedSearchGames"
    />
  </div>

  <!-- Search Results -->
  <div v-if="isSearchResultVisible" class="game-search-results">
    <!-- Search Result Header -->
    <div class="game-search-header">
      <img src="@/assets/img/search.png" alt="Result Icon" />
      <span>{{ $t('slot.result') }}</span>
      <button class="game-search-close-button" @click="closeSearch">X</button>
    </div>

    <!-- Search Instructions or Results -->
    <div class="game-search-content">
      <div v-if="!gameSearchResults.length">
        <p v-if="!gameSearchStarted">{{ $t('slot.enter3') }}</p>
        <p v-else>{{ $t('slot.noresult') }}</p>
      </div>
      <div
        v-else
        class="game-search-list"
      >
      <div
        v-for="(game, index) in gameSearchResults"
        :key="index"
        class="game-search-item"
        @click="startGame(game)" 
      >
          <img :src="game.banner" alt="Game Banner" class="game-search-banner" />
        </div>
      </div>
    </div>

    <!-- Recent Searches -->
    <div v-if="recentSearches.length" class="game-search-recent-searches">
      <div class="game-search-recent-header">
        <img src="@/assets/img/recent.png" alt="Recent Icon" />
        <span>{{ $t('slot.recent') }}</span>
      </div>
      <div class="game-search-recent-items">
        <span
          v-for="(item, index) in recentSearches"
          :key="index"
          @click="searchRecent(item)"
          class="game-search-recent-item"
        >
          {{ item }}
        </span>
      </div>
    </div>
  </div>
</div>
         


         <!-- Kategoriler -->
         <div
v-for="(category, index) in filteredCategories"
:key="index"
class="category-section"
>
<div class="category-header">
  <div class="category-title">
    <img :src="category.icon" alt="Category Icon" class="category-icon" />
    <h2>{{ category.name }}</h2>
  </div>
  <router-link
    v-if="category.name !== 'Favorites'"
    :to="`/category/${category.route}`"
    class="see-all-button"
  >
     {{ $t('slot.seeall') }}
  </router-link>
</div>
<div class="games-list">
  <div
v-for="(game, idx) in category.games.slice(0, 18)"
:key="idx"
class="game-item"
@click="startGame(game)"
>
    <a :href="game.href" class="game-card">
      <img
        :src="game.banner"
        alt="Game Banner"
        class="game-banner"
      />
      <span class="game-name">{{ game.game_name }}</span>
          <span class="provider-code">{{ game.provider_code }}</span>
       
        <div class="like-button" @click.prevent="toggleLike(game)">
          <img
            :src="game.liked
              ? require('@/assets/img/liked.png')
              : require('@/assets/img/like.png')"
            alt="Like Button"
            class="like-img"
          />
        </div>
      
    </a>
  </div>
</div>
</div>

      <HomeDepositMethods />
      
  </div>
</template>

<script>
  import Bets from '@/components/bets/Bets';
  import HomeBannerUser from '@/components/home/HomeBannerUser';
  import HomeBannerNews from '@/components/home/HomeBannerNews'
  import HomeFeaturedLink from '@/components/home/HomeFeaturedLink';
  import HomeGameElement from '@/components/home/HomeGameElement';
  import HomePlaceholderElement from '@/components/home/HomePlaceholderElement';
  import HomeDepositMethods from '@/components/home/HomeDepositMethods';
  import axios from 'axios';
  import debounce from "lodash/debounce";
  import { mapGetters } from 'vuex';

  export default {
      data() {
      return {

        gridItems: [
    {
      image: require('@/assets/img/banner/slot.png'),
      alt: 'Banner 1',
      title: 'Casino',
      link: '/category/slots',
    },
    {
      image: require('@/assets/img/banner/casino.png'),
      alt: 'Banner 2',
      title: 'Sport' ,
      link: '/category/live-casino',
    },
    {
      image: require('@/assets/img/banner/spor.png'),
      alt: 'Banner 3',
      title: 'Rivo Originals',
      link: '/',
    },
   
    {
      image: require('@/assets/img/banner/virtual.png'),
      alt: 'Banner 4',
      title: 'Sports',
      
      link: '/game/23248',
    },
    {
      image: require('@/assets/img/banner/turnuva.png'),
      alt: 'Banner 4',
      title: 'turnuva',
      
      link: '/leaderboard',
    },
    {
      image: require('@/assets/img/banner/bonus.png'),
      alt: 'Banner 4',
      title: 'Sports',
      
      link: '/promotions',
    },
    
    
  ],
        banners: [
      { 
        image: require('@/assets/img/banner/1/1.png'), 
       
        
      },
      { 
        image: require('@/assets/img/banner/1/2.png'), 
     
      
      },
      { 
        image: require('@/assets/img/banner/1/3.png'), 
     
      
      },
      { 
        image: require('@/assets/img/banner/1/4.png'), 
        
        
      },
      { 
        image: require('@/assets/img/banner/1/5.png'), 
       
      
      },
      { 
        image: require('@/assets/img/banner/1/6.png'), 
       
      
      }
    ],
          currentIndex: 0,
          selectedCategory: 'Lobby',
          intervalId: null, // Loop için
          transitioning: false, // Animasyon kontrolü
          liked: false, // Başlangıç değeri
          categoryButtons: [
          { name: this.$t('sidebar.lobby'), img: require('@/assets/img/sidebar/7.png') },
      { name: this.$t('sidebar.favorites'), img: require('@/assets/img/sidebar/heart.png') },
      { name: this.$t('sidebar.slots'), img: require('@/assets/img/sidebar/5.png') },
      { name: this.$t('sidebar.liveCasino'), img: require('@/assets/img/sidebar/14.png') },
      { name: this.$t('sidebar.miniGames'), img: require('@/assets/img/sidebar/joystick.png') },
      { name: this.$t('sidebar.originals'), img: require('@/assets/img/sidebar/admin.svg') },
    
          ],
          categories: [
              {
                  name: 'RivoBIT Originals',
                  games: [
                      { game_name: 'Rivo Crash', banner: require('@/assets/img/games/crash.png'), route: 'crash', category: 'RivoBIT Originals' },
                      { game_name: 'Rivo Battle', banner: require('@/assets/img/games/battles.png'), route: 'battles', category: 'RivoBIT Originals' },
                      { game_name: 'Rivo Duels', banner: require('@/assets/img/games/duels.png'), route: 'duels', category: 'RivoBIT Originals' },
                      { game_name: 'Rivo Roulette', banner: require('@/assets/img/games/roll.png'), route: 'roll', category: 'RivoBIT Originals' },
                      { game_name: 'Rivo Mines', banner: require('@/assets/img/games/mines.png'), route: 'mines', category: 'RivoBIT Originals' },
                      { game_name: 'Rivo Tower', banner: require('@/assets/img/games/tower.png'), route: 'towers', category: 'RivoBIT Originals' },
                      { game_name: 'Rivo Case', banner: require('@/assets/img/games/case.png'), route: 'unbox' , category: 'RivoBIT Originals'},
                 
                    
                     
                      
                  
                  ],
                  route: 'originals',
                  icon: require('@/assets/img/category2/1.png')
              },
              { name: this.$t('categories.hot'), games: [], route: 'hots', icon: require('@/assets/img/category2/2.png') },
      { name: this.$t('categories.slots'), games: [], route: 'slots', icon: require('@/assets/img/category2/3.png') },
      { name: this.$t('categories.liveCasino'), games: [], route: 'live-casino', icon: require('@/assets/img/category2/4.png') },
      { name: this.$t('categories.blackjack'), games: [], route: 'black-jack', icon: require('@/assets/img/category2/6.png') },
      { name: this.$t('categories.roulette'), games: [], route: 'roulette', icon: require('@/assets/img/category2/7.png') },
      { name: this.$t('categories.miniGames'), games: [], route: 'mini-games', icon: require('@/assets/img/category2/5.png') },
      { name: this.$t('categories.bingoLottery'), games: [], route: 'bingo-l', icon: require('@/assets/img/category2/8.png') }
              
          ],
          playerCounts: {}, // Her oyun için oyuncu sayıları
          currentIndex: 0,
          intervalId: null,
          gameSearchQuery: "",
    gameSearchResults: [],
    recentSearches: JSON.parse(localStorage.getItem("recentSearches")) || [],
    isSearchResultVisible: false,
    gameSearchStarted: false,
          isMobile: false // Mobil olup olmadığını belirlemek için

      };
  },
  computed: {
      ...mapGetters(['authUser']), // Vuex store'dan authUser'ı alıyoruz
     
      },
  async created() {
  try {
    // Her kategori için API'ye istek atılıyor
    const fetchCategories = this.categories.map(async (category) => {
      const response = await axios.get(`/games_api/${category.route}`);
      
      if (response.data.success) {
        category.games = response.data.data; // Kategori oyunları güncelleniyor
      }
    });

    await Promise.all(fetchCategories); // Tüm istekler tamamlanana kadar bekle
  } catch (error) {
    console.error('Error fetching categories:', error);
  }
},
  computed: {
      filteredCategories() {
  if (this.selectedCategory === 'Lobby') {
    return this.categories; // Tüm kategorileri göster
  } else if (this.selectedCategory === 'Favorites') {
    return [
      {
        name: 'Favorites',
        games: this.favorites,
        icon: require('@/assets/img/sidebar/9.png'),
      },
    ];
  } else {
    return this.categories.filter(
      (category) => category.name === this.selectedCategory
    );
  }
},
      sliderStyle() {
  const move = this.isMobile
    ? this.currentIndex * -100 // Mobilde %100 genişlikte kaydır
    : this.currentIndex * -33.33; // Masaüstünde %33.33 genişlikte kaydır
  return {
    transform: `translateX(${move}%)`,
    transition: 'transform 0.5s ease-in-out',
  };
},
extendedBanners() {
  // Slider'ın sonsuz döngüsü için tekrar eden bannerlar
  return [...this.banners, ...this.banners.slice(0, 3)];
}
},
  mounted() {
      const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
this.favorites = storedFavorites;

// Favorilerden oyunların `liked` durumunu güncelle
this.favorites.forEach((fav) => {
  this.categories.forEach((category) => {
    const game = category.games.find(
      (game) => game.game_name === fav.game_name
    );
    if (game) {
      game.liked = true;
    }
  });
});
      this.checkIfMobile();
      this.startSlider(); // Slider'ı başlat
      window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
      clearInterval(this.intervalId);
      window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    navigateTo(link) {
  // Yönlendirme için Vue Router kullanıyorsanız
  this.$router.push(link);

  // Eğer sayfa yeniden yüklenecekse:
  // window.location.href = link;
},

    redirectToPromotion() {
    this.$router.push('/promotions'); // Vue Router ile yönlendirme
  },
  startGame(game) {
if (game.route && game.category === 'RivoBIT Originals') {
  // Rivo Originals için özel rota
  this.$router.push({ path: `/${game.route}` });
} else {
  // Diğer oyunlar için varsayılan rota
  this.$router.push({ name: 'GameStart', params: { id: game.game_code } });
}
},

      showSearchResult() {
    this.isSearchResultVisible = true;
  },
  closeSearch() {
    this.isSearchResultVisible = false;
  },
  async handleSearchGames() {
    if (this.gameSearchQuery.length < 3) {
      this.gameSearchResults = [];
      this.gameSearchStarted = false;
      return;
    }

    this.gameSearchStarted = true;

    try {
      const response = await axios.get("/search_games", {
        params: { query: this.gameSearchQuery },
      });

      if (response.data.success) {
        this.gameSearchResults = response.data.data;

        // Save search to recent searches
        if (!this.recentSearches.includes(this.gameSearchQuery)) {
          this.recentSearches.push(this.gameSearchQuery);
          localStorage.setItem(
            "recentSearches",
            JSON.stringify(this.recentSearches)
          );
        }
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  },
  debouncedSearchGames: debounce(function () {
    this.handleSearchGames();
  }, 300),
  searchRecent(query) {
    this.gameSearchQuery = query;
    this.handleSearchGames();
  },
      selectCategory(categoryName) {
  this.selectedCategory = categoryName;
},
      toggleLike(game) {
  game.liked = !game.liked; // "like" durumunu değiştir
  this.updateFavorites(game); // Favori oyunları güncelle
},
updateFavorites(game) {
  localStorage.setItem('favorites', JSON.stringify(this.favorites));

  if (game.liked) {
    // Favorilere ekle
    if (!this.favorites.some((fav) => fav.game_name === game.game_name)) {
      this.favorites.push(game);
    }
  } else {
    // Favorilerden çıkar
    this.favorites = this.favorites.filter(
      (fav) => fav.game_name !== game.game_name
    );
  }
},

      initializePlayerCounts(games) {
  // Her oyun için başlangıç değerini ayarla
  games.forEach((game) => {
    const storedCount = localStorage.getItem(`playerCount_${game.game_code}`);
    if (storedCount) {
      this.$set(this.playerCounts, game.game_code, parseInt(storedCount, 10));
    } else {
      const randomCount = Math.floor(Math.random() * 301) + 100; // 100-400 arasında rastgele
      this.$set(this.playerCounts, game.game_code, randomCount);
      localStorage.setItem(`playerCount_${game.game_code}`, randomCount);
    }
  });

  // Değerleri dinamik olarak artırma/azaltma
  clearInterval(this.playerCountInterval); // Önceki interval'i temizle
  this.playerCountInterval = setInterval(() => {
    games.forEach((game) => {
      const currentCount = this.playerCounts[game.game_code];
      const randomChange = Math.floor(Math.random() * 11) - 5; // -5 ile +5 arasında rastgele değişim
      const newCount = Math.max(0, currentCount + randomChange); // 0'ın altına inmesin
      this.$set(this.playerCounts, game.game_code, newCount);
      localStorage.setItem(`playerCount_${game.game_code}`, newCount);
    });
  }, 2500); // 2.5 saniyede bir değişim
},

      checkIfMobile() {
  this.isMobile = window.innerWidth <= 768; // Mobil kontrolü
},
      
  
      checkIfMobile() {
          this.isMobile = window.innerWidth <= 950; // Ekran boyutu 950px'den küçükse mobil
      },
      startSlider() {
  this.intervalId = setInterval(() => {
    if (this.currentIndex >= (this.isMobile ? this.banners.length : this.banners.length / 3)) {
      this.currentIndex = 0; // Başa dön
    } else {
      this.currentIndex++;
    }
  }, 5000); // 5 saniyede bir geçiş
},
nextSlide() {
  clearInterval(this.intervalId);
  if (this.currentIndex >= (this.isMobile ? this.banners.length : this.banners.length / 3)) {
    this.currentIndex = 0;
  } else {
    this.currentIndex++;
  }
  this.startSlider();
},
prevSlide() {
  clearInterval(this.intervalId);
  if (this.currentIndex === 0) {
    this.currentIndex = this.isMobile
      ? this.banners.length - 1
      : Math.ceil(this.banners.length / 3) - 1;
  } else {
    this.currentIndex--;
  }
  this.startSlider();
}
},
      name: 'Home',
      metaInfo: {
          title: 'RivoBIT: The Innovative Crypto Gambling Site'
      },
      components: {
          Bets,
          HomeBannerUser,
          HomeBannerNews,
          HomeFeaturedLink,
          HomeGameElement,
          HomePlaceholderElement,
          HomeDepositMethods
      }
  }
</script>

<style scoped>
  .home {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    
  }

  .home-banner-slider {
position: relative;
width: 100%;
overflow: hidden;
}

.top-banner {
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Alt boşluk */
    
}

.top-banner img {
    width: 100%;
    height: auto;
    border-radius: 25px;
    
}

.slider-container {
display: flex;
transition: transform 0.5s ease-in-out;
}

.banner-item {
flex: 0 0 33.33%; /* Masaüstü için 3'lü görünüm */
box-sizing: border-box;
padding: 5px;
position: relative;
}

.banner-item img {
width: 100%;
height: auto;
border-radius: 10px;
background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
  background-color: #090f1e;
  box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.6);
}

.banner-content {
position: absolute;
top: 50%;

transform: translateY(-50%); /* Yatay ortalama */
text-align: left; /* Yazıyı sola yasla */
color: white;

padding: 10px;
border-radius: 8px;
z-index: 1;
}

.banner-title {
font-size: 18px;
font-weight: bold;
margin-bottom: 10px;
}

.banner-description {
font-size: 12px;
}

/* Mobil düzen */
@media (max-width: 768px) {
.banner-item {
  flex: 0 0 100%; /* Mobilde banner tam genişlikte görünür */
}
}

.player-count {
position: absolute;
top: 10px;
right: 10px;
background: rgba(0, 0, 0, 0.7);
color: white;
font-size: 12px;
padding: 5px 10px;
border-radius: 5px;
z-index: 2;
}

  .home .home-banner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
  }

  .home .home-featured,
  .home .home-games {
      width: 1250px;
      position: relative;
      padding: 70px 0;

  }

  .home .home-featured {
      margin-top: -85px;
  }

  .home .home-games {
      padding: 65px 0 40px 0;
      background: radial-gradient(45% 37% at 50% 105%, rgba(83, 69, 168, 0.65) 0%, rgba(3, 28, 49, 0) 100%);
  }

 

  .home .home-games::before {
      background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #3b7eb7 50%, rgba(2, 25, 44, 0) 100%);
  }

  .home .home-games::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(90deg, rgba(2, 25, 44, 0) 0%, #5345a8 50%, rgba(2, 25, 44, 0) 100%);
  }

  .home .featured-title,
  .home .games-title {
      width: 205px;
      height: 43px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Rubik';
      font-size: 24px;
      font-weight: 900;
      color: #ffffff;
  }

  .home .featured-title::before,
  .home .games-title::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(255deg, #00ffc2 -25%, #00aa6d 100%);
      clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
      z-index: -1;
  }


  .home .games-title::before {
      background: linear-gradient(255deg, #3b7eb7 -25%, #145081 100%);
  }

  .home-featured {
display: grid;
gap: 20px;
margin-top: 20px;
grid-template-columns: repeat(4, 1fr); /* Masaüstünde 4 sütun */
}

.home-grid {
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Masaüstü için 4 sütun */
    gap: 20px; /* Elemanlar arası boşluk */
    margin: 20px 0;
}



.grid-item {
cursor: pointer;
text-align: center;
}

.grid-item img {
max-width: 100%;
height: auto;
display: block;
border-radius: 10px;

}

.featured-img {
width: 100%;
height: 140px;
border-radius: 10px;
transition: transform 0.3s ease, box-shadow 0.3s ease;
cursor: pointer;
}

/* Mouse üzerine gelme efekti */
.featured-img:hover {
transform: scale(1.1); /* Resmi büyüt */
box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Gölge efekti */
}

/* Mobil düzen - 2x2 düzeni */
@media (max-width: 768px) {
.home-featured {
  grid-template-columns: repeat(2, 1fr); /* Mobilde 2 sütun */
}
.featured-img  {
  width: 100%;
  height: 95px;
}
}

  .home .featured-content,
  .home .games-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
  }

  @media only screen and (max-width: 1600px) {

      .home .home-banner,
      .home .home-featured,
      .home .home-games {
          width: 100%;
      }

  }

  @media only screen and (max-width: 950px) {

      .home {
          padding: 0px 10px 443px 10px;
      }

  }
  .category-section {
      width: 100%;
      max-width: 100%;
      margin: 20px 0;
  }

  .category-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 12px;
      border-radius: 5px;
      color: white;
  }

  .category-title {
      display: flex;
      align-items: center;
  }

  .category-icon {
      width: 30px;
      height: 30px;
      margin-right: 8px;
  }

  .see-all-button {
      font-size: 14px;
      color: #00ffc2;
      text-decoration: none;
  }

  .category-buttons {
display: flex;
flex-wrap: nowrap; /* Butonlar tek satırda sıralanır */
justify-content: flex-start; /* Butonlar sola yaslanır */
align-items: center; /* Dikey hizalama */
gap: 15px; /* Butonlar arası boşluk */
margin: 20px 0;
padding: 10px 0;
overflow-x: auto; /* Yatay kaydırma etkin */
scrollbar-width: none; /* Firefox için kaydırma çubuğunu gizle */
width: 100%;
background: #23393387 !important;
padding-left: 10px;
border-radius: 10px;
}

.category-button {
display: flex;
white-space: nowrap; /* Yazının alt satıra geçmesini engelle */
flex-direction: row; /* Görsel ve yazıyı yan yana yerleştir */
align-items: center;
cursor: pointer;
padding: 10px;
border-radius: 5px;
transition: background 0.3s ease, color 0.3s ease;

}

.category-button img {
width: 30px; /* Görsel genişliği */
height: 30px; /* Görsel yüksekliği */
margin-right: 10px; /* Görsel ile yazı arasındaki boşluk */
}

.category-button span {
font-size: 14px;
font-weight: bold;
color: white;
}

.category-button.active {
background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%); /* Aktif butonun arka plan rengi */


color: #00ffc2;
}

.game-search-container {
position: relative;

width: 100%;
}

.game-search-box {
display: flex;
align-items: center;
padding: 10px 15px;
background: #233933 !important;
border-radius: 8px;
width: 100%;


color: white;
}

.game-search-icon {
width: 24px;
height: 24px;
margin-right: 10px;
}

.game-search-input {
width: 100%;
background-color: transparent;
border: none;
outline: none;
color: white;
font-size: 16px;
font-family: "Rubik", sans-serif;
}

.game-search-input::placeholder {
color: #aaa;
font-size: 14px;
}

/* Search Results Container */
.game-search-results {
background: #233933 !important;
color: white;
padding: 20px;
border-radius: 8px;

margin-top: 10px;
position: relative;
}

/* Close Button */
.game-search-close-button {
position: absolute;
top: 10px;
right: 10px;
background: none;
border: none;
color: white;
font-size: 18px;
font-weight: bold;
cursor: pointer;
}

/* Search Results Header */
.game-search-header {
display: flex;
align-items: center;
margin-bottom: 15px;
font-size: 18px;
font-weight: bold;
color: white;
}

.game-search-header img {
width: 20px;
height: 20px;
margin-right: 10px;
}

/* Search Results Content */
.game-search-content {
display: flex;
gap: 15px;
overflow-x: auto; /* Yatay kaydırma */
white-space: nowrap; /* Tek sıra */
padding-bottom: 10px;
border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.game-search-item {
flex-shrink: 0; /* Kaydırılabilir öğe */
width: 120px;
height: 120px;
border-radius: 8px;
overflow: hidden;
background-color: #01344e;
cursor: pointer;
transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.game-search-item img {
width: 100%;
height: 100%;
object-fit: cover;
}

.game-search-item:hover {
transform: scale(1.05);
box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

/* Search Message */
.game-search-message {
font-size: 14px;
color: #888;
margin-top: 10px;
text-align: center;
}

/* Recent Searches */
.game-search-recent-searches {
margin-top: 20px;
}

.game-search-recent-header {
display: flex;
align-items: center;
margin-bottom: 10px;
font-size: 16px;
color: white;
}

.game-search-recent-header img {
width: 20px;
height: 20px;
margin-right: 10px;
}

.game-search-recent-items {
display: flex;
flex-wrap: wrap;
gap: 10px;
}

.game-search-recent-item {
background-color: #01344e;
padding: 5px 10px;
border-radius: 5px;
color: white;
font-size: 14px;
cursor: pointer;
white-space: nowrap;
transition: background-color 0.3s ease, transform 0.3s ease;
}

.game-search-recent-item:hover {
background-color: #005d7e;
transform: scale(1.05);
}

.game-search-list {
display: flex;
gap: 15px;
}


.games-list {
display: flex;
flex-wrap: nowrap;
overflow-x: auto; /* Yatay kaydırma */
overflow-y: hidden; /* Dikey kaydırmayı gizle */
-webkit-overflow-scrolling: touch; /* Mobil cihazlarda düzgün kaydırma */
}

  .game-item {
  display: flex;
  flex-direction: column; /* İsimleri resmin altına yerleştirir */
  align-items: center;
  flex: 0 0 auto;
  margin-right: 10px;
  text-align: center; /* Bu, metinlerin ortalanmasını sağlar */
  position: relative;
}
.game-card {
display: block;
width: 100%;
height: 100%;
position: relative;
text-decoration: none;
color: white;
}

.game-item a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none; /* Linkin altını çizmez */
  color: inherit; /* Metin rengini devralır */
}

.game-banner {
width: 170px;
height: 170px;

border-radius: 10px;

}

@media only screen and (max-width: 768px) {
.game-banner {
        width: 170px;
        height: 170px;
    }
}

/* Mouse üzerine gelme efekti */


.game-name {
margin-top: 10px;
font-family: 'Rubik';
font-size: 10px;
font-weight: 500;
color: #ffffff;
text-align: center; /* Metni ortala */
word-wrap: break-word; /* Uzun kelimeleri böl */
word-break: break-word; /* Kelimeleri taşır */
overflow-wrap: break-word; /* Tarayıcı uyumluluğu için */
max-width: 146px; /* Genişliği sınırla (banner boyutuna uygun) */
line-height: 1.2; /* Satır aralığını belirle */
}


.game-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(9, 40, 65, 0.664);
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding: 10px;
opacity: 0;
transition: opacity 0.3s ease;
border-radius: 10px;
}

/* RTP Value */
.rtp-value {
align-self: flex-start;
font-size: 12px;
font-weight: bold;
color: white;

padding: 5px 8px;
border-radius: 5px;
margin-top: 5px;
}

.play-icon {
width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
box-shadow: rgba(1, 230, 169, 0.15) 0px 4px 25px;
cursor: pointer;
transition: background 0.3s ease;
position: relative;
}



.play-img {
width: 50px; /* İkon genişliği */
height: 50px; /* İkon yüksekliği */
object-fit: contain; /* Görselin taşmasını önler */
}

/* Game Info */
.game-info {
text-align: center;
margin-top: -15px;
}
.game-info {
text-align: center;

}

.game-name {
font-size: 14px;
font-weight: bold;
}

.provider-code {
font-size: 12px;
opacity: 0.7;
color: white;
}

/* Like Button */
.like-button {
position: absolute;
bottom: -5px;
right: 10px;
cursor: pointer;
}

.like-img {
width: 20px;
height: 20px;
transition: transform 0.3s ease;
}

.like-button:hover .like-img {
transform: scale(1.2);
}

/* Hover Effects */

.game-item:hover .game-overlay {
opacity: 1;
}

  @media only screen and (max-width: 1600px) {
      .home .home-banner,
      .home .home-featured,
      .home .home-buttons,
      .home .home-games {
          width: 100%;
      }
      .home .home-buttons {
          flex-wrap: nowrap;
      }
  }

  @media only screen and (max-width: 950px) {
      .home {
          padding: 0px 10px 443px 10px;
      }

      .home .home-buttons {
          flex-wrap: nowrap;
      }

      .search-container {
          padding: 0 10px;
      }

      .search-box {
          max-width: 100%;
      }

      .home .button-item {
          width: 90px;
          margin: 0 5px;
      }
  }

  @media only screen and (max-width: 768px) {
    .home-grid {
        grid-template-columns: repeat(3, 1fr); /* Mobil için 2 sütun */
    }
}

</style>
