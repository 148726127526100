<template>
  <div class="login-credentials">
  <!-- Giriş Yöntemi Seçici -->
   <!-- Giriş Yöntemi Seçici (Sadece Login İçin Görünecek) -->
   <div v-if="tab === 'login'" class="credentials-method-selector">
    <button v-on:click="setLoginMethod('email')" :class="{ 'active': loginMethod === 'email' }">{{ $t('login.mailw') }}</button>
    <button v-on:click="setLoginMethod('phone')" :class="{ 'active': loginMethod === 'phone' }">{{ $t('login.phonew') }}</button>
    <button v-on:click="setLoginMethod('username')" :class="{ 'active': loginMethod === 'username' }">{{ $t('login.usernamew') }}</button>
  </div>

  <!-- Email Alanı (Sadece Login İçin Görünecek) -->
  <div v-if="tab === 'login' && loginMethod === 'email'" class="credentials-element">
    <div class="element-title">{{ $t('login.mail') }}</div>
    <div class="element-input">
      <input v-model="loginEmail" type="text" :placeholder="$t('register.mailPlaceholder')" />
    </div>
  </div>

  <!-- Telefon Alanı (Sadece Login İçin Görünecek) -->
  <div v-if="tab === 'login' && loginMethod === 'phone'" class="credentials-element">
    <div class="element-title">{{ $t('login.phone') }}</div>
    <div class="element-input2 phone-input">
      <input v-model="selectedCountryCode" type="text" class="country-code" placeholder="+0" maxlength="4" />
      <input v-model="loginPhone" type="text" :placeholder="$t('register.phonePlaceholder')" maxlength="10" class="phone-number" />
    </div>
  </div>

  <!-- Kullanıcı Adı Alanı (Sadece Login İçin Görünecek) -->
  <div v-if="tab === 'login' && loginMethod === 'username'" class="credentials-element">
    <div class="element-title">{{ $t('login.username') }}</div>
    <div class="element-input">
      <input v-model="loginUsername" type="text" :placeholder="$t('register.usernamePlaceholder')" />
    </div>
  </div>

  <div v-if="tab === 'login'" class="credentials-element">
    <div class="element-title">{{ $t('login.password') }}</div>
    <div class="element-input">
      <input v-model="loginPassword" type="password" :placeholder="$t('register.passwordPlaceholder')" />
      <button v-if="tab === 'login'" v-on:click="$parent.modalSetTab('forgot')" class="button-forgot">
        <div class="button-inner">
          <span>{{ $t('login.forgot') }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path
              d="M224 64c44.2 0 80 35.8 80 80v48H144V144c0-44.2 35.8-80 80-80zM80 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H368V144C368 64.5 303.5 0 224 0S80 64.5 80 144zM256 320v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32s32 14.3 32 32z" />
          </svg>
        </div>
      </button>
    </div>
  </div>

 
  <!-- First Row: Name & Surname / Username -->
  <div v-if="tab === 'register'" class="credentials-row">
    <div class="credentials-element">
      <div class="element-title">{{ $t('register.name') }}</div>
      <div class="element-input">
        <input v-model="loginNameSurname" type="text" :placeholder="$t('register.namePlaceholder')" />
      </div>
    </div>
    
    <div class="credentials-element">
      <div class="element-title">{{ $t('register.username') }}</div>
      <div class="element-input">
        <input v-model="loginUsername" type="text" :placeholder="$t('register.usernamePlaceholder')"  />
      </div>
    </div>
  </div>

  <!-- Second Row: Phone / Email -->
  <div v-if="tab === 'register'" class="credentials-row">
    <div class="credentials-element" >
      <div class="element-title">{{ $t('register.phone') }}</div>
      <div class="element-input2 phone-input">
        <input v-model="selectedCountryCode" type="text" class="country-code" placeholder="+0" maxlength="4" />
        <input v-model="loginPhone" type="text" :placeholder="$t('register.phonePlaceholder')" maxlength="10" class="phone-number" />
      </div>
    </div>

    <div class="credentials-element">
      <div class="element-title">{{ $t('register.mail') }}</div>
      <div class="element-input">
        <input v-model="loginEmail" type="text" :placeholder="$t('register.mailPlaceholder')" />
      </div>
    </div>
  </div>

  <div v-if="tab === 'register'" class="credentials-row">

  <div class="credentials-element">
    <div class="element-title">{{ $t('register.birthday') }}</div>
    <div class="element-input">
      <input v-model="loginBirthday" type="date" placeholder="" />
    </div>
  </div>

  
  <div class="credentials-element">
    <div class="element-title">{{ $t('register.idnumber') }}</div>
    <div class="element-input">
      <input v-model="loginIdNumber" type="text" :placeholder="$t('register.idPlaceholder')" maxlength="11" />
    </div>
  </div>
</div>

  

  <!-- Third Row: Password / Password Confirm -->
  <div v-if="tab === 'register'" class="credentials-row">
    <div class="credentials-element">
      <div class="element-title">{{ $t('register.password') }}</div>
      <div class="element-input">
        <input v-model="loginPassword" type="password" :placeholder="$t('register.passwordPlaceholder')"  />
      </div>
    </div>

    <div class="credentials-element">
      <div class="element-title">{{ $t('register.passwordag') }}</div>
      <div class="element-input">
        <input v-model="loginPasswordConfirm" type="password" :placeholder="$t('register.passwordagPlaceholder')" />
      </div>
    </div>

    

  </div>

 

  <!-- Terms & Conditions -->
 

  <!-- Action Buttons -->
  <div class="credentials-buttons">
    <button 
        v-on:click="$parent.modalSetTab(tab === 'login' ? 'register' : 'login')" 
        class="button-toggle" 
        v-bind:class="{ 'button-active': tab === 'register' }">
        <div class="button-inner">
            {{ tab === 'login' ? $t('buttons.register') : $t('buttons.login') }}
        </div>
    </button>
    <button 
        v-on:click="loginActionButton()" 
        class="button-action" 
        v-bind:disabled="authSendLoginLoading === true">
        <div class="button-inner">
            {{ $t(`buttons.${tab}`) }}
        </div>
    </button>
</div>

  <div v-if="tab === 'register'" class="credentials-info">
    {{ $t('register.credent') }}
    <button v-on:click="loginTermsButton()" class="button-terms">{{ $t('register.terms') }}</button>.
  </div>
</div>
</template>


<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
      name: 'LoginCredentials',
      props: ['tab'],
      data() {
          return {
              loginMethod: 'username', // Varsayılan giriş yöntemi kullanıcı adı
              loginUsername: null,
              loginEmail: null,
              loginPhone: null,
              loginNameSurname: null,
              loginBirthday: null, // Doğum günü
              loginIdNumber: null, // Kimlik numarası
              loginPassword: null,
              selectedCountryCode: "90", // Varsayılan ülke kodu
              loginPasswordConfirm: null,
             
          }
      },
     
      methods: {
          setLoginMethod(method) {
              this.loginMethod = method;
          },
          ...mapActions([
              'modalsSetShow',
              'modalsSetData',
              'notificationShow',
              'authSendCredentialsLogin',
              'authSendCredentialsRegister'
          ]),
          loginActionButton() {
    let loginData = {};

    // E-posta doğrulama
    if (this.loginMethod === 'email') {
        if (this.loginEmail === null || this.loginEmail.trim() === '') {
            this.notificationShow({ type: 'error', message: this.$t('errors.invalidEmail') });
            return;
        }
        loginData = { email: this.loginEmail, password: this.loginPassword };
    }

    // Telefon numarası doğrulama
    if (this.loginMethod === 'phone') {
        const fullPhoneNumber = `${this.selectedCountryCode}${this.loginPhone}`;
        if (this.loginPhone === null || this.loginPhone.trim() === '') {
            this.notificationShow({ type: 'error', message: this.$t('errors.invalidPhone') });
            return;
        }
        loginData = { phone: fullPhoneNumber, password: this.loginPassword };
    }

    // Kullanıcı adı doğrulama
    if (this.loginMethod === 'username') {
        if (this.loginUsername === null || this.loginUsername.trim() === '') {
            this.notificationShow({ type: 'error', message: this.$t('errors.invalidUsername') });
            return;
        }
        loginData = { username: this.loginUsername, password: this.loginPassword };
    }

    // Şifre doğrulama
    if (this.loginPassword === null || this.loginPassword.trim() === '') {
        this.notificationShow({ type: 'error', message: this.$t('errors.invalidPassword') });
        return;
    }

    if (this.tab === 'register') {
        // Şifre tekrar doğrulama
        if (this.loginPassword !== this.loginPasswordConfirm) {
            this.notificationShow({ type: 'error', message: this.$t('errors.passwordMismatch') });
            return;
        }

        if (!this.loginBirthday) {
            this.notificationShow({ type: 'error', message: this.$t('errors.missingBirthday') });
            return;
        }

        // Kimlik numarası doğrulama
        if (!this.loginIdNumber || this.loginIdNumber.trim() === '' || this.loginIdNumber.length !== 11) {
            this.notificationShow({ type: 'error', message: this.$t('errors.invalidIdNumber') });
            return;
        }

        // Telefon numarası ve alan kodunu birleştir
        const fullPhoneNumber = `${this.selectedCountryCode}${this.loginPhone}`;

        // Kayıt işlemi için verileri ayarla
        this.modalsSetData({
            typeCaptcha: 'credentialsRegister',
            data: {
                username: this.loginUsername,
                email: this.loginEmail,
                phone: fullPhoneNumber,
                name: this.loginNameSurname,
                password: this.loginPassword,
                birthday: this.loginBirthday,
                idNumber: this.loginIdNumber
            }
        });
    } else {
        // Giriş işlemi için verileri ayarla
        this.modalsSetData({
            typeCaptcha: 'credentialsLogin',
            data: loginData
        });
    }

    setTimeout(() => { this.modalsSetShow('Captcha'); }, 200);
},

          loginTermsButton() {
              this.modalsSetShow(null);
              setTimeout(() => { this.modalsSetShow('Terms'); }, 300);
          }
      },
      computed: {
          ...mapGetters([
              'authSendLoginLoading'
          ])
      }
  }
</script>



<style scoped>
  .login-credentials {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .login-credentials .credentials-element {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      
  }

  .login-credentials .credentials-element:first-child {
      margin-top: 0;
  }

  .login-credentials .element-title {
      font-size: 14px;
      font-weight: 800;
      color: white;
  }
  .credentials-row {
  width: 100%;
  display: flex;
  justify-content: space-between; /* İki form elemanı arasında boşluk */
  margin-top: 20px;
}
.credentials-element {
  width: 48%; /* İki form elemanının yan yana sığması için genişlik */
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .login-credentials .element-input {
      width: 90%;
      height: 36px;
      position: relative;
      color: white;
      padding: 1px;
      filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
  }

  .login-credentials .element-input::before {
     
    color: white;
      
  }

  .login-credentials .element-input input {
      width: 100%;
      height: 100%;
      padding: 0 0 0 25px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
      border-radius: 10px;
      
  }

  .login-credentials .element-input input::placeholder {
    color: white;;
  }
  .login-credentials .element-input2 input::placeholder {
    color: white;
      font-size: 16px;
      font-weight: 600;
  }

  .login-credentials button.button-forgot {
    height: 26px;
  position: absolute;
  top: 44%;
  right: 15px;
  transform: translate(0, -50%);
  }

  .login-credentials button.button-forgot::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #1a4f63;
      border-radius: 15px;
      z-index: -1;
  }

  .login-credentials button.button-forgot::after {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      top: 1px;
      left: 1px;
      background-color: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
      border-radius: 15px;
      z-index: -1;
  }

  .login-credentials button.button-forgot .button-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 15px;
      background: #1a4f63;
      clip-path: polygon(9px 0, calc(100% - 9px) 0, 100% 25%, 100% 75%, calc(100% - 9px) 100%, 9px 100%, 0 75%, 0 25%);
  }

  .login-credentials button.button-forgot .button-inner span {
      font-size: 13px;
      font-weight: 800;
      color: white;
  }

  .login-credentials button.button-forgot .button-inner svg {
      width: 17px;
      height: 17px;
      display: none;
      fill: white;
  }

  .login-credentials .credentials-info {
      width: 100%;
      margin-top: 35px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #49687d;
  }

  .login-credentials .credentials-info button.button-terms {
      text-decoration: underline;
      font-size: 14px;
      font-weight: 700;
      color: #db7d48;
  }

  .login-credentials .credentials-buttons {
      display: flex;
      align-items: center;
      margin-top: 35px;
  }

  .login-credentials button.button-toggle {
      width: 200px;
      height: 45px;
      position: relative;
      margin-right: 20px;
      padding: 1px;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.35));
      transition: filter 0.3s ease;
      z-index: 1;
  }

  .login-credentials button.button-toggle.button-active {
      filter: drop-shadow(0px 4px 25px rgba(1, 230, 169, 0.15)) 
              drop-shadow(0px 2px 25px rgba(15, 41, 63, 0.35));
  }

  .login-credentials button.button-action {
      width: 180px;
      height: 45px;
      position: relative;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.35)) drop-shadow(0px 4px 25px rgba(1, 230, 169, 0.15));
  }

  .login-credentials button.button-toggle::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #1a4f63;
      border-radius: 15px;
      z-index: -1;
  }

  .login-credentials button.button-toggle.button-active::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #01e0a3 100%);
  }

  .login-credentials button.button-toggle::after {
      content: '';
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      top: 1px;
      left: 1px;
      background-color: #07243a;
      border-radius: 15px;
      z-index: -1;
  }

  .login-credentials button.button-toggle .button-inner,
  .login-credentials button.button-action .button-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 800;
      border-radius: 15px;
  }

  .login-credentials button.button-toggle .button-inner {
    color: #00ffc2;
    background: radial-gradient(60% 60% at 50% 50%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(255deg, rgba(0, 255, 194, 0.05) 0%, rgba(0, 170, 109, 0.05) 100%);
      
  }

  .login-credentials button.button-toggle.button-active .button-inner {
      color: #00ffc2;
      background: radial-gradient(60% 60% at 50% 50%, rgba(0, 255, 194, 0.2) 0%, rgba(0, 0, 0, 0) 100%), 
                  linear-gradient(255deg, rgba(0, 255, 194, 0.05) 0%, rgba(0, 170, 109, 0.05) 100%);
  }

  .login-credentials button.button-action .button-inner {
      color: #736b6b;
      background: yellow;
  }

  @media only screen and (max-width: 430px) {

      .login-credentials .element-input input {
          padding: 0 12px 0 25px;
          border-radius: 10px;
      }


      .login-credentials button.button-forgot .button-inner span {
          display: none;
      }

      .login-credentials button.button-forgot .button-inner svg {
          display: block;
      }

      .login-credentials .credentials-buttons {
          width: 100%;
          flex-direction: column;
      }

      .login-credentials button.button-toggle,
      .login-credentials button.button-action {
          width: 100%;
      }

      .login-credentials button.button-toggle {
          margin-bottom: 10px;
          margin-right: 0;
      }

  }
  .phone-input {
  display: flex;
  align-items: center;
  width: 90%;
  height: 36px;
}



.phone-input {
  display: flex;
  width: 100%;
}

.country-code, .phone-number {
  width: 90%;
  
}

.country-code {
  width: 20%; /* Alan kodu için dar bir genişlik */
  margin-right: 10px;
  text-align: center;
}

.phone-number {
  flex: 1; /* Telefon numarası alanı daha geniş olacak */
  margin-left: -10px;
}

.credentials-buttons {
  margin-top: 35px;
  display: flex;
  justify-content: center;
}

.credentials-buttons button {
  margin-left: 10px;
}

.element-title {
  font-size: 14px;
  font-weight: 800;
  color: white;
}

.element-input2 input {
      margin-right: 11px;
      height: 100%;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
      
}
.credentials-method-selector {
display: flex;
justify-content: center;
margin-bottom: 20px;
}
.credentials-method-selector button {
margin: 0 10px;
padding: 10px 20px;
border-radius: 10px;
border: none;
cursor: pointer;
color: white;
background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
font-size: 12px;
  font-weight: 600;
transition: background-color 0.3s ease;

}
.credentials-method-selector button.active {
  color: #736b6b;
  background: yellow;
  
}
</style>