<template>
    <div class="user-profile-container">
      <h2>{{ $t('Kullanıcı Bilgileri') }}</h2>
  
      <!-- Kullanıcı Bilgileri Kartı -->
      <div class="user-info-card">
        <div class="user-info-item">
          <span class="label">{{ $t('Kullanıcı ID') }}:</span>
          <span class="value">{{ authUser.user._id }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('Kullanıcı Adı') }}:</span>
          <span class="value">{{ authUser.user.username }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('E-posta') }}:</span>
          <span class="value">{{ authUser.user.local.email }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('Telefon') }}:</span>
          <span class="value">{{ authUser.user.phone }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('Kimlik Numarası') }}:</span>
          <span class="value">{{ authUser.user.identity.idNumber }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('Ad Soyad') }}:</span>
          <span class="value">{{ authUser.user.name }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('Doğum Tarihi') }}:</span>
          <span class="value">{{ formatDate(authUser.user.birthday) }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('XP') }}:</span>
          <span class="value">{{ authUser.user.xp }}</span>
        </div>
        <div class="user-info-item">
          <span class="label">{{ $t('Rütbe') }}:</span>
          <span class="value">{{ authUser.user.rank }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    computed: {
      ...mapGetters(['authUser']), // Vuex'ten authUser bilgilerini al
    },
    methods: {
      // Tarih formatlama
      formatDate(dateString) {
        if (!dateString) return '-'; // Eğer tarih yoksa boş değer döndür
        const date = new Date(dateString);
        return date.toLocaleDateString('tr-TR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .user-profile-container {
    padding: 20px;
    background: #233933;
    border-radius: 10px;
    color: #fff;
    max-width: 600px;
    margin: 0 auto;
  }
  
  h2 {
    text-align: center;
    color: #FFD700;
    margin-bottom: 20px;
  }
  
  .user-info-card {
    background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .user-info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #34495e;
  }
  
  .user-info-item:last-child {
    border-bottom: none; /* Son öğenin alt çizgisini kaldır */
  }
  
  .label {
    font-weight: bold;
    color: #FFD700;
    flex: 1;
  }
  
  .value {
    color: #fff;
    flex: 2;
    text-align: right;
  }
  
  /* Responsive Tasarım */
  @media (max-width: 768px) {
    .user-profile-container {
      padding: 15px;
    }
  
    .user-info-card {
      padding: 15px;
    }
  
    .user-info-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .value {
      text-align: left;
      margin-top: 5px;
    }
  }
  </style>