<template>
    <div class="game-history-container">
      <h2>Oyun Geçmişi{{ $t('Bakiye') }}</h2>
  
      <!-- Tablo -->
      <table class="game-history-table">
        <thead>
          <tr>
            <th>{{ $t('Banner') }}</th>
            <th>{{ $t('Oyun Adı') }}</th>
            <th class="hide-on-mobile"> {{ $t('Oyun Türü') }}</th>
            <th>{{ $t('Bahis Miktarı') }}</th>
            <th class="hide-on-mobile"> {{ $t('Kazanç Miktarı') }}</th>
            <th class="hide-on-mobile"> {{ $t('Önceki Bakiye') }}</th>
            <th class="hide-on-mobile"> {{ $t('Sonraki Bakiye') }}</th>
            <th class="hide-on-mobile"> {{ $t('Tarih') }}</th>
            
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedHistory" :key="index">
            <td>
              <img :src="item.banner" alt="Banner" class="banner-image" v-if="item.banner" />
              <span v-else>{{ $t('Banner Yok') }}</span>
            </td>
            <td class="game-name">{{ item.game_name }}</td>
            <td class="hide-on-mobile">{{ item.game_type }}</td>
            <td>{{ formatBalance(item.bet_money) }}</td>
            <td class="hide-on-mobile">{{ formatBalance(item.win_money) }}</td>
            <td class="hide-on-mobile">{{ formatBalance(item.balance_before) }}</td>
            <td class="hide-on-mobile">{{ formatBalance(item.balance_after) }}</td>
            <td class="hide-on-mobile"> {{ formatDate(item.created_at) }}</td>
            
          </tr>
        </tbody>
      </table>
  
      <!-- Sayfalandırma -->
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">{{ $t('Önceki') }}</button>
        <span>{{ $t('Sayfa') }} {{ currentPage }} / {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">{{ $t('Sonraki') }}</button>
        <span class="total-records">{{ $t('Toplam Kayıt') }}: {{ gameHistory.length }}</span>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import axios from 'axios';
  
  export default {
    data() {
      return {
        gameHistory: [], // Tüm oyun geçmişi
        currentPage: 1, // Mevcut sayfa
        itemsPerPage: 15, // Sayfa başına gösterilecek veri sayısı
      };
    },
    computed: {
      ...mapGetters(['authUser']),
  
      // Tarihe göre sıralanmış ve sayfalandırılmış veri
      paginatedHistory() {
        const sortedHistory = this.gameHistory.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return sortedHistory.slice(start, end);
      },
  
      // Toplam sayfa sayısı
      totalPages() {
        return Math.ceil(this.gameHistory.length / this.itemsPerPage);
      },
    },
    mounted() {
      this.fetchGameHistory();
    },
    methods: {
      async fetchGameHistory() {
        try {
          const userIdentifier = this.authUser.user._id || this.authUser.user.phone;
          const response = await axios.get(`/game-history/${userIdentifier}`);
          this.gameHistory = response.data.history;
        } catch (error) {
          console.error('Oyun geçmişi çekilirken hata oluştu:', error);
        }
      },
  
      // Tarih formatlama
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('tr-TR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        });
      },
  
      // Bakiye formatlama (noktadan sonra 3 basamak)
      formatBalance(value) {
        return parseFloat(value).toFixed(3);
      },

       // Metni kısaltma (ilk 6 harf + ...)
    shortenText(text, maxLength) {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },
  
      // Önceki sayfa
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
  
      // Sonraki sayfa
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .game-history-container {
    padding: 20px;
    background: #233933;
    border-radius: 10px;
    color: #fff;
  }
  
  /* Tablo Stili */
  .game-history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .game-history-table th,
  .game-history-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #34495e;
  }
  
  .game-history-table th {
    background: radial-gradient(220% 220% at 50% 15%, rgba(41, 186, 151, 0.4) 0%, rgba(6, 35, 60, 0.4) 100%);
    color: #FFD700;
  }
  
  .game-history-table tbody tr:hover {
    background-color: #34495e;
  }
  
  .banner-image {
    width: 150px;
    height: auto;
    border-radius: 5px;
  }
  
  /* Sayfalandırma */
  .pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .pagination button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .total-records {
    margin-left: auto;
    font-weight: bold;
  }

  .game-name {
  max-width: 100px; /* İstenilen genişlik */
  white-space: nowrap; /* Metni tek satırda tut */
  overflow: hidden; /* Taşan metni gizle */
  text-overflow: ellipsis; /* Taşan metni ... ile göster */
}
  
  /* Mobil Görünümü */
  @media (max-width: 768px) {
    .game-history-table {
      display: block;
      overflow-x: auto; /* Yatay kaydırma */
      white-space: nowrap; /* Satırların kaymaması için */
    }
  
    .game-history-table th,
    .game-history-table td {
      min-width: 100px; /* Mobilde sütun genişliği */
    }
  
    .hide-on-mobile {
      display: none; /* Mobilde belirli sütunları gizle */
    }
  
    .banner-image {
      width: 70px; /* Mobilde banner boyutu küçültüldü */
    }
    .game-name {
    max-width: 80px; /* Mobilde daha küçük genişlik */
  }
    
  }
  </style>