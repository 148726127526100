<template>
    <div class="modal-chat-rules">
        <div class="rules-title">
            <span class="gradient-green">{{ $t('modal.27') }}</span>
        </div>
        <div class="rules-list">

            <div class="list-element">
                <span class="gradient-green">1.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.28') }}</span>
                    {{ $t('modal.29') }}
                </div>
            </div>
            <div class="list-element">
                <span class="gradient-green">2.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.30') }}</span>
                   {{ $t('modal.31') }}
                </div>
            </div>
            <div class="list-element">
                <span class="gradient-green">3.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.32') }}</span>
                    {{ $t('modal.33') }}
                </div>
            </div>
            <div class="list-element">
                <span class="gradient-green">4.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.34') }}</span>
                    {{ $t('modal.35') }}
                </div>
            </div>
            <div class="list-element">
                <span class="gradient-green">5.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.36') }}</span>
                    {{ $t('modal.37') }}
                </div>
            </div>
            <div class="list-element">
                <span class="gradient-green">6.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.38') }}</span>
                    {{ $t('modal.39') }}
                </div>
            </div>
            <div class="list-element">
                <span class="gradient-green">7.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.40') }}</span>
                    {{ $t('modal.41') }}
                </div>
            </div>
            <div class="list-element">
                <span class="gradient-green">8.</span>
                <div class="element-info">
                    <span class="gradient-green">{{ $t('modal.42') }}</span>
                   {{ $t('modal.43') }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalChatRules'
    }
</script>

<style scoped>
    .modal-chat-rules {
        width: 970px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px 36px 0 0;
        border-radius: 15px;
        background: #233933 ;
    }

    .modal-chat-rules::after {
        content: '';
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 0 0 15px 15px;
        background: linear-gradient(0deg, #07263d 0%, rgba(7, 36, 58, 0) 100%);
    }

    .modal-chat-rules .rules-title {
        text-align: center;
        text-transform: uppercase;
        font-size: 32px;
        font-weight: 900;
    }

    .modal-chat-rules .rules-list {
        width: 100%;
        height: 570px;
        margin-top: 45px;
        padding: 0 37px 60px 78px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .modal-chat-rules .rules-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .modal-chat-rules .rules-list::-webkit-scrollbar-track {
        margin-bottom: 60px;
        background-color: #062234;
    }

    .modal-chat-rules .rules-list::-webkit-scrollbar-thumb {
        background-color: #1e5164;
    }

    .modal-chat-rules .list-element {
        display: flex;
        align-items: center;
        margin-top: 14px;
    }

    .modal-chat-rules .list-element:first-child {
        margin-top: 0;
    }

    .modal-chat-rules .list-element span {
        font-family: 'Rubik';
        font-size: 64px;
        font-weight: 900;
    }

    .modal-chat-rules .element-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 15px;
        font-size: 16px;
        font-weight: 700;
        color: #bbbfd0;
    }

    .modal-chat-rules .element-info span {
        font-size: 20px;
        font-weight: 800;
    }

    @media only screen and (max-width: 990px) {

        .modal-chat-rules {
            width: calc(100vw - 20px);
            padding: 85px 20px 0 0;
        }

    }
</style>