<template>
    <button v-on:click="modalsSetShow('Login')" class="auth-button">
        <div class="button-inner">
            <IconRoblox />
            {{ $t('mainte.3') }}
        </div>
    </button>
</template>

<script>
    import { mapActions } from 'vuex';
    import IconRoblox from '@/components/icons/IconRoblox';

    export default {
        name: 'AuthButton',
        components: {
            IconRoblox
        },
        methods: {
            ...mapActions(['modalsSetShow'])
        }
    }
</script>

<style scoped>
    button.auth-button {
        height: 37px;
        
       
       
    }

    button.auth-button .button-inner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 22px;
        font-size: 12px;
        font-weight: 800;
        color: #ffffff;
        background: linear-gradient(1800deg, #6c684d 0%, #ffe418 50%) !important;
        
        border-radius: 10px;
        
    }

    button.auth-button .button-inner svg {
        margin-right: 11px;
        fill: #ffffff;
    }
</style>
