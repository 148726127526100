<template>
    <div class="modal-privacy">
        <div class="privacy-header">
            <span class="gradient-green">{{ $t('modal.95') }}</span>
            <p>{{ $t('modal.96') }}</p>
            <p>{{ $t('modal.97') }}</p>
        </div>
        <div class="privacy-list">

            <div class="list-element">
                <div class="element-title">{{ $t('modal.98') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.99') }}</p>
                    <p>{{ $t('modal.100') }}</p>
                    <p>{{ $t('modal.101') }}</p>
                    <p>{{ $t('modal.102') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.103') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.104') }}
                        <ul>
                            <li>{{ $t('modal.105') }}</li>
                            <li>{{ $t('modal.106') }}</li>
                            <li>{{ $t('modal.107') }}</li>
                            <li>{{ $t('modal.108') }}</li>
                            <li>{{ $t('modal.109') }}</li>
                        </ul>
                    </p>
                    <p>{{ $t('modal.110') }}</p>
                    <p>{{ $t('modal.111') }}</p>
                    <p>{{ $t('modal.112') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.113') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.114') }}</p>
                    <p>{{ $t('modal.115') }}</p>
                    <p>{{ $t('modal.116') }}</p>
                    <p>{{ $t('modal.117') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.118') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.119') }}</p>
                    <p>{{ $t('modal.120') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.121') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.122') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.123') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.124') }}</p>
                </div>
            </div>

            <div class="list-element">
                <div class="element-title">{{ $t('modal.125') }}</div>
                <div class="element-text">
                    <p>{{ $t('modal.126') }}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ModalPrivacy'
    }
</script>

<style scoped>
    .modal-privacy {
        width: 1000px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 0 30px;
        border-radius: 15px;
        background: #233933 
    }

    .modal-privacy .privacy-header {
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid #335361;
    }

    .modal-privacy .privacy-header span  {
        font-size: 32px;
        font-weight: 900;
    }

    .modal-privacy .privacy-header p  {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-privacy .privacy-list {
        width: 100%;
        height: 600px;
        padding: 30px 20px 30px 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .modal-privacy .privacy-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .modal-privacy .privacy-list::-webkit-scrollbar-track {
        margin: 30px 0;
        background-color: #062234;
    }

    .modal-privacy .privacy-list::-webkit-scrollbar-thumb {
        background-color: #1e5164;
    }

    .modal-privacy .list-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }

    .modal-privacy .list-element:first-child {
        margin-top: 0;
    }

    .modal-privacy .element-title {
        font-size: 24px;
        font-weight: 800;
        color: #ffffff;
    }

    .modal-privacy .element-text {
        width: 100%;
        margin-top: 15px;
        padding-left: 30px;
    }

    .modal-privacy .element-text p {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .modal-privacy .element-text p:first-child {
        margin-top: 0;
    }

    .modal-privacy .element-text ul {
        margin-top: 15px;
        padding-left: 50px;
    }

    @media only screen and (max-width: 1190px) {

        .modal-privacy {
            width: calc(100vw - 20px);
        }

    }

    @media only screen and (max-width: 700px) {

        .modal-privacy {
            padding: 80px 15px 0 15px;
        }

    }
</style>
